
import AImageWithDummy from '~/components/common/AImage/AImageWithDummy/index.vue'

export default {
  name: 'AImage',
  components: { AImageWithDummy },
  inheritAttrs: false,
  props: {
    src: {
      type: [String, null],
      required: false,
      default: null
    },
    customSrcSet: {
      type: [String, null],
      required: false,
      default: null
    },
    webp: {
      type: Boolean,
      required: false,
      default: true
    },
    to: {
      type: String,
      required: false,
      default: null
    },
    openInNewTab: {
      type: Boolean,
      required: false,
      default: false
    },
    provider: {
      type: String,
      required: false,
      default: 'static'
    },
    withDummy: {
      type: Boolean,
      required: false,
      default: true
    },
    fit: {
      type: String,
      required: false,
      default: 'contain'
    }
  },
  computed: {
    imgAttrs() {
      return {
        ...this.$attrs,
        srcset: this.srcSet,
        src: this.src,
        provider: this.provider,
        ...this.additionalOptions
      }
    },
    additionalOptions() {
      return this.webp ? { format: 'webp' } : {}
    },
    srcSet() {
      if (this.customSrcSet) {
        return this.customSrcSet
      }

      if (!this.src) return ''

      const { width, height, modifiers } = this.$attrs

      const resultingWidth = modifiers?.size || width
      const resultingHeight = modifiers?.size || height

      const x1 = this.$img(
        this.src,
        {
          width: resultingWidth,
          height: resultingHeight,
          ...this.additionalOptions
        },
        { provider: this.provider }
      )
      const x2 = this.$img(
        this.src,
        {
          width: resultingWidth * 2,
          height: resultingHeight * 2,
          ...this.additionalOptions
        },
        { provider: this.provider }
      )

      return `${x1} 1x, ${x2} 2x`
    }
  }
}
